import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from '../images/Logo.png';
import './HeaderMobile.css';

class HeaderMobile extends Component {
  render() {
    return (
      <Navbar className='visible-xs-* visible-sm-* hidden-md hidden-lg'>
        <Navbar.Header>
          <div className='navbar-wrapper'>
            <Navbar.Brand>
              <img
                className='mobile-navbar-logo-img'
                src={Logo}
                alt='Abraham Heating and Air Conditioning Logo'
              />
            </Navbar.Brand>
            <div className='company-name-phone navbar-text'>
              <img
                className='mobile-navbar-logo-img'
                src={Logo}
                alt='Abraham Heating and Air Conditioning Logo'
              />
              Abraham <br className='company-break' /> Heating &amp; Air Conditioning
              <div className='phone-small'>(972) 492-3164</div>
              <span className='license-text'>TACL B 003861C</span>
            </div>
            <div className='navbar-text navbar-text-right visible-xs-inline-block visible-sm-inline-block'>
              (972) 492-3164
          </div>
          </div>
        </Navbar.Header>
      </Navbar>
    );
  }
}

export default HeaderMobile;
