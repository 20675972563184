import React, { Component } from 'react';
import './Home.css';

import Brands from '../images/Brandsv5.png';
import AboutFounder from './Founder';
import Services from '../Services/Services';
import CarrierResearch from './CarrierResearch';

class Home extends Component {
  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <div className='descriptor preamble'>
              We are a Bryant Dealer and Service all Major Brands
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <div className='preamble'>
              <img
                src={Brands}
                className='brand-logos'
                alt='Brands serviced by Abraham Heating and Air Conditioning'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-10 col-md-offset-1'>
            <div className='about-us-text'>
              <p>
                <strong>Abraham Heating & Air Conditioning</strong>
              </p>
              <p className='left-pad'>
                Committed to a job done right, Abraham Heating & Air
                Conditioning has been serving the Dallas Metroplex since 1975.
                Every job has been inspected or completed by the company’s
                founder, Rod Abraham, to ensure that the quality of work meets
                the highest quality standards.
              </p>
              <AboutFounder />
              <CarrierResearch />
            </div>
          </div>
        </div>
        <Services />
      </div>
    );
  }
}

export default Home;
