import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faSnowflake from "@fortawesome/fontawesome-free-solid/faSnowflake";

import React, { Component } from "react";
import "./Services.css";
import Flame from "../images/flame.svg";

class Services extends Component {
  render() {
    return (
      <div className="container">
        <div className="services-container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 text-center services-banner">
              Residential &amp; Commercial HVAC Services
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-sm-offset-3">
              <div className="services-column-header">
                <FontAwesomeIcon icon={faSnowflake} size="3x" color="#79D1F3" />
                <br />
                <span className="services-header-title">
                  <strong>Air Conditioning</strong>
                </span>
                <br />
                <span className="services-basic-title">
                  Installation
                  <br />
                  Replacement
                  <br />
                  Repair
                </span>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="services-column-header">
                <img src={Flame} className="fire-svg" alt="flame" />
                <br />
                <span className="services-header-title">
                  <strong>Heating</strong>
                </span>
                <br />
                <span className="services-basic-title">
                  Installation
                  <br />
                  Replacement
                  <br />
                  Repair
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
