import React from "react";
import "./Research.css";

const CarrierResearch = () => (
  <div className="section-wrapper">
    <p>
      <strong>Carrier Indoor Coil Research</strong>
    </p>
    <p className="left-pad">
      Carrier has published research about what causes indoor coil corrosion
      which leads to refrigerant leaks on older systems. The report can be
      viewed and/or downloaded by clicking the link below.
    </p>
    <p>
      <a
        className="carrier-report pad-left"
        href={`${process.env.PUBLIC_URL}CarrierIndoorCoilCorrosionReport.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Carrier Report
      </a>
    </p>
  </div>
);

export default CarrierResearch;
