import React, { Component } from 'react';
import Logo from '../images/Logo.png';
import './HeaderDesktop.css';

class HeaderDesktop extends Component {
  render() {
    return (
      <div className='container hidden-xs hidden-sm visible-md-* visible-lg-*'>
        <div className='navbar-container'>
          <div className='top-container'>
            <div className='top-left-container'>
              <div className='navbar-logo'>
                <img
                  className='navbar-logo-img'
                  src={Logo}
                  alt='Abraham Heating and Air Conditioning Logo'
                />
              </div>
            </div>
            <div className='top-middle-container'>
              <div className='company-name'>
                <strong>
                  <span className='family-name'>ABRAHAM</span>
                  <br />
                  Heating & Air Conditioning</strong>
                <br />
                <span className='smaller-header-text'>Since 1975</span>
              </div>
            </div>
            <div className='top-right-container'>
              <div className='liscense-contact-info'>
                <strong>(972) 492-3164</strong>
                <br />
                <span className='license-text'>TACL B 003861C</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderDesktop;
