import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './App.css';

import HeaderDesktop from './Header/HeaderDesktop';
import HeaderMobile from './Header/HeaderMobile';
import Footer from './Footer/Footer';
import Home from './Home/Home';

if (process.env.REACT_APP_GA_ACTIVE === 'true') {
  ReactGA.initialize('UA-115477984-2');
  ReactGA.pageview('home');
}

class App extends Component {
  render() {
    return (
      <div>
        <HeaderDesktop />
        <HeaderMobile />
        <div className="container">
          <Home />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
