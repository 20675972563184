import React, { Component } from 'react';
import './Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <div className='container footer-container'>
        <div className='row col-md-10 col-md-offset-1'>
          <div className='footer-wrapper'>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='footer-header'>
                  Phone
                </div>
                <div className='footer-detail'>
                  (972) 492-3164
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='footer-header'>
                  Email
                </div>
                <div className='footer-detail'>
                  info@abrahamhvac.com
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='footer-header'>
                  Address
                </div>
                <div className='footer-detail'>
                  PO Box 116906
                  <br />
                  Carrollton, TX 75011
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 text-center'>
                <strong><em>Licensed and Insured in the State of Texas</em></strong>
                <br />
                Site by <a
                  href='http://www.jwomack.com'
                  className='footer-link'>Jonathan Womack
                  </a>.
                Copyright &copy; 2018 Abraham Heating and Air Conditioning
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
