import React from 'react';

const AboutFounder = () => (
  <div className='section-wrapper'>
    <p><strong>About the Founder</strong></p>
    <p className='left-pad'>
      Rod Abraham trained and worked for nearly 8 years as an aircraft mechanic in the
      United States Air Force before attending Spartan School of Aeronautics where he
      trained in both Aviation Maintenance Technology and Non-Destructive Testing
      programs.Upon graduating from Spartan, Rod received his FAA Airframe & Powerplant
      license and began working as an A&P Instructor for Braniff International.
      Applying his skills from his military training and his careful attention to detail,
      Rod has served his community in the heating and air conditioning field for over
      40 years. Rod has the determination and ingenuity to tackle the toughest jobs,
      and his greatest concern in business is doing a job that he can be proud of.
      Rod works hard to ensure that every job completed by his crew is done as well as
      he would want done for his own family.
    </p>
  </div>
);

export default AboutFounder;
